export default {
  headline: "Software Engineer",
  intro: [
    `I am a dedicated Software Engineer and IT Professional with hands-on experience in software development, web design, and IT support. My background includes a Master’s degree in Engineering Management from La Trobe University and a Bachelor’s degree in Information Science and Engineering from Jain University. I specialize in developing efficient, customized WordPress websites, providing IT support, and delivering seamless user experiences across a variety of industries including restaurants, medical clinics, and cleaning services.`,
    `My technical skills span hardware and software troubleshooting, network configuration, system diagnostics, and operating system management, with proficiency in tools such as Microsoft 365, Google Workspace, VMware, Cisco Packet Tracer, WSL (Windows Subsystem for Linux), and PowerShell.`,
    `Additionally, I have earned the Google IT Support certification, which has honed my abilities in:
    - Troubleshooting and Customer Service
    - Networking and Wireless networking
    - Operating Systems (Linux and Windows) & Command-line expertise
    - System Administration & Security best practices
    - Cloud Computing concepts, Automation, and Project Planning`,
    `I began my career as a Junior Software Engineer at Resignal, where I optimized system processes, managed PostgreSQL databases, handled Docker containers, and utilized GitHub for version control. Currently, I support both web development and IT processes as an Intern in Web Design and IT Support, assisting with custom WordPress solutions while ensuring system security and technical reliability for businesses.`,
    `Outside of tech, I serve as a Gaming Supervisor & Attendant at Roxburgh Park Hotel, where I lead a team, resolve technical issues for customers, and manage financial transactions—skills that have further developed my leadership and problem-solving abilities.`,
    `I am passionate about learning emerging technologies, collaborating with teams, and tackling complex technical challenges. My goal is to contribute to innovative projects that merge software engineering, IT support, and web development to deliver impactful solutions.`,
    `Feel free to connect with me for opportunities, sharing insights, or collaboration on exciting projects.`,
  ],
  facts: [
    "DOB:17th March 1999",
    "Nationality:Nepali ",
    "Country:$1",
    "Mother-tongue:Nepali",
    "Other Languages:English,Hindi",
    "Address:Kathmandu,Nepal",
  ],
  trivias: [`Gamer`, `$6`, `$5`, `$7`, `$2 , $3 , $4`],
  links: {
    $1: ["Nepal", "https://en.wikipedia.org/wiki/Nepal"],
    $2: ["Blogue", "https://blogue.tech/@/sujanchhetri"],
    $3: ["Medium", "https://medium.com/@suJJJan"],
    $4: ["Hackernoon", "https://hackernoon.com/u/sujan-chhetri"],
    $5: [
      "Postman student expert",
      "https://badgr.com/public/assertions/96g4_3DiTq2dxFwWTrC7Dw",
    ],
    $6: [
      "Hackernoon Contributor of the Year",
      "https://noonies.tech/award/hacker-noon-contributor-of-the-year-future",
    ],
    $7: [
      "Postman student Leader",
      "https://badgr.com/public/assertions/0cvwCXgXTUmFMPPiy8ANaA",
    ],
  },
};
