import "./App.scss";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Taskbar from "./components/Taskbar";
import Links from "./components/Links";
import TabLink from "./components/TabLink";
import { Social } from "./assets";
import pages from "./pages";
import * as storage from "local-storage";

const SocialLinks = {
	Github: "https://github.com/sujanchhetri",
	Linkedin: "https://www.linkedin.com/in/sujjjan",
	facebook: "https://www.facebook.com/sujan.kshetri.73",
	twitter: "https://twitter.com/suJJJan",
	instagram: "https://www.instagram.com/_s_ujan/",
	youtube:"https://www.youtube.com/channel/UCfV4T9cWvyMji-wl38PqaNg",
	anchor: "https://anchor.fm/sujanchhetri",
};
const storeKey = {
	theme: "theme",
	welcome: "welcome",
};

function App() {
	const storedTheme = storage.get(storeKey.theme) || "Classic";
	const shouldWelcome = storage.get(storeKey.welcome) !== false ? true : false;

	const [theme, setTheme] = React.useState(storedTheme);
	const [welcome, showWelcome] = React.useState(shouldWelcome);
	setTimeout(() => {
		storage.set(storeKey.welcome, false);
		showWelcome(false);
	}, 3000);

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<div id="welcome" className={welcome ? "" : "hidden"}>
				<big>Hello there!</big>
			</div>
			<div id="desktop" className={theme}>
				<Links theme={theme} />
				<div className="social-media">
					{Object.keys(SocialLinks).map((key) => (
						<TabLink
							key={key}
							className="social-link"
							href={SocialLinks[key]}
						>
							<img src={Social[key]} alt={key} />
						</TabLink>
					))}
				</div>
				<Switch>
					{Object.keys(pages).map((path) => (
						<Route
							key={path}
							path={`/${path}/`}
							component={pages[path].component}
						/>
					))}
				</Switch>
			</div>
			<Taskbar
				theme={theme}
				setTheme={(theme) => {
					storage.set(storeKey.theme, theme);
					setTheme(theme);
				}}
			/>
		</BrowserRouter>
	);
}

export default App;
