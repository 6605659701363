import React from "react";
import "./Snips.scss";
import Window from "../components/Window";
import { useLocation } from "react-router-dom";
import pages from "../pages";
import {blogs,links} from "../data/blogs";
import { parseLinks } from "../_utils";

function Snips() {
  const { pathname } = useLocation();
  return (
    <Window title={pages[pathname.slice(1)].title} bodyClass="window__resume">
      <iframe src="https://anchor.fm/sujanchhetri/embed/episodes/The-Future-of-JavaScript-Doesnt-Exist-es27fv" height="102px" width="400px" frameborder="0" scrolling="no"></iframe>
    </Window>
  );
}

export default Snips;
