import React from "react";
import "./Taskbar.scss";
import { useLocation } from "react-router-dom";
import { Icon, themes } from "../assets";
import pages from "../pages";

function Taskbar({ theme, setTheme }) {
  const [menu, showMenu] = React.useState(false);
  const { pathname } = useLocation();
  const path = pathname.slice(1);
  return (
    <>
      <div id="taskbar" className={theme}>
        <div
          id="settings"
          className={menu ? "active" : ""}
          style={{ backgroundImage: `url(${Icon("settings", theme)})` }}
          onClick={() => showMenu(!menu)}
        ></div>
        {path && (
          <div className="item">
            <img src={Icon(path, theme)} alt="" />
            <label>{pages[path].title}</label>
          </div>
        )}
        <div className="item right">
          Copyright &copy; 2021 Sujan Chhetri</div>
      </div>
    </>
  );
}

export default Taskbar;
